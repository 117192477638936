import {
  FormatBoldRounded,
  InsertEmoticon,
  Lock,
  LockOpen,
  Redo,
  Undo,
} from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {
  Box,
  Divider,
  IconButton as MuiIconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { styled } from '@mui/system';
import { Editor, EditorContent } from '@tiptap/react';
import { useContext, useRef, useState, useEffect } from 'react';
import { PostContext } from '../../context/PostContext';
import { PostHandlers, PostStatus, UsePostReturnType } from '../../hooks/usePost';
import { convertToBold } from '../../utils';
import AIAssistantMenu from '../AiAssistant/AIAssistantMenu';
import EmojiComponent from '../SelectorComponents/EmojiSelector';
import TaggerSelector from '../SelectorComponents/TaggerSelector';
import CharCounter from './CharCounter';
import './styles.css';
import { Tag } from '../../__generated__/graphql';
import { AuthContext } from '../../context/AuthContext';
import SignIcon from '../../assets/sign.svg';
import { useNavigate } from 'react-router-dom';
import { GET_POST } from '../../graphql/queries';
import { useTranslation } from 'react-i18next';
import { usePostEditor } from '../../hooks/usePostEditor';

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  width: '20px',
  '& .MuiSvgIcon-root': {
    width: '20px',
  },
  '&.MuiIconButton-root': {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface Props {
  postId?: string;
  content?: string | null;
  placeholder?: string;
  status?: PostStatus;
  handlers?: PostHandlers;
  aiAssistant?: boolean;
  resizable?: boolean;
  onChange?: (html: string, json: any, action?: Tag) => void;
  advocacyEditable?: boolean;
  toggleAdvocacyEditable?: () => void;
  isUndoable?: boolean;
  isLink?: boolean;
  isAdmin?: boolean;
}

export const TextEditor = ({
  postId,
  content = null,
  status,
  aiAssistant = false,
  onChange,
  advocacyEditable,
  toggleAdvocacyEditable,
  isUndoable = false,
  isLink = false,
  isAdmin = false,
}: Props) => {
  const { t } = useTranslation();
  const [emojiComponentOpen, setEmojiComponentOpen] = useState(false);
  const [taggerComponentOpen, setTaggerComponentOpen] = useState(false);
  const [isSignatureChecked, setIsSignatureChecked] = useState(false);
  const [showNoSignatureModal, setShowNoSignatureModal] = useState(false);
  const { postState, editor: EditorAdmin } = useContext(PostContext);

  const editorUser = usePostEditor({
    postState: postState as UsePostReturnType,
    content: content ?? '',
    editable: !status?.isEditDisabled,
    postRef: postState?.postRef,
    onChange,
  });

  const editor = isAdmin ? EditorAdmin : editorUser;

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const { data } = useQuery(GET_POST, {
    variables: { postId: postId! ?? '' },
    skip: !postId,
  });

  const anchorRef = useRef(null);
  // Function to check if the signature is present in the editor content
  const isSignaturePresent = (editor: Editor) => {
    const signatureText = data?.post?.brand?.signature?.trim();
    if (!signatureText) return false;

    const editorContent = editor.getHTML();
    const signatureIndex = editorContent.lastIndexOf(signatureText);

    // Check if the signature is at the end of the content
    return (
      signatureIndex !== -1 &&
      signatureIndex + signatureText.length === editorContent.length
    );
  };

  // When component mounts or when content changes, check if signature is present
  useEffect(() => {
    if (editor && data?.post?.brand?.signature) {
      const signatureExists = isSignaturePresent(editor);
      setIsSignatureChecked(signatureExists);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, content, data?.post?.brand?.signature]);

  useEffect(() => {
    if (onChange && postState?.post._id) {
      onChange(postState?.post.content?.body as string, postState?.post.content?.json);
    }
  }, [
    onChange,
    postState?.post._id,
    postState?.post.content?.body,
    postState?.post.content?.json,
  ]);

  const insertSignature = (editor: Editor): void => {
    // Case 1: The post belongs to the logged-in user

    // Check if logged-in user has a signature
    if (data?.post?.brand?.signature && data.post.brand.signature !== '') {
      removeSignature(editor);
      editor.commands.focus('end');
      const signatureContent = `<div data-signature="true">${data.post.brand.signature}&nbsp;&nbsp;</div>`;
      editor.commands.insertContent(signatureContent);
      onChange && onChange(editor.getHTML(), editor.getJSON());
    } else {
      // Logged-in user doesn't have a signature, show modal
      setShowNoSignatureModal(true);
    }

    // Case 2: The post belongs to a different user (brand user)
  };

  const removeSignature = (editor: Editor): void => {
    if (editor && data?.post?.brand?.signature) {
      const signatureText = data?.post?.brand?.signature.trim();

      editor.commands.command(({ tr, dispatch }) => {
        const { doc } = tr;

        let signaturePos: number | null = null;
        let signatureNodeSize: number | null = null;

        // Traverse the document and find a node containing the signature text
        doc.descendants((node, pos) => {
          if (node.isText && node.text?.includes(signatureText)) {
            signaturePos = pos;
            signatureNodeSize = node.nodeSize;
            return false; // Stop traversal once the signature is found
          }
          return true; // Continue traversal
        });

        // If found, remove the signature node
        if (signaturePos !== null && signatureNodeSize !== null && dispatch) {
          tr.delete(signaturePos, signaturePos + signatureNodeSize);
          dispatch(tr);
        }

        return true;
      });

      onChange && onChange(editor.getHTML(), editor.getJSON());
    }
  };

  const handleSignatureToggle = (): void => {
    if (!editor) return;

    if (!postId) {
      setShowNoSignatureModal(true);
    }

    if (isSignatureChecked) {
      // Remove the existing signature if it's already inserted
      removeSignature(editor);
      setIsSignatureChecked(false);
    } else {
      // Try to insert the signature based on the user (logged-in or brand user)
      insertSignature(editor);
      setIsSignatureChecked(true);
    }
  };

  const handleEmojiComponentOpen = () => {
    setEmojiComponentOpen(true);
  };

  const handleTaggerComponentOpen = () => {
    setTaggerComponentOpen(true);
  };

  const handleTaggerComponentClose = () => {
    setTaggerComponentOpen(false);
  };

  const handleEmojiComponentClose = () => {
    setEmojiComponentOpen(false);
  };

  const handleEmojiInputChange = (editor: Editor, emoji: string) => {
    editor.commands.insertContent(emoji);
    onChange && onChange(editor.getHTML(), editor.getJSON());
  };

  const handleTagger = (editor: Editor, tag: string, tagId: string) => {
    editor.commands.insertContent(
      `<span data-type="mention" class="mention" data-label="${tag}" data-id="${tagId}" contenteditable="false">${tag}</span>`,
    );
    editor.commands.focus();
    setTaggerComponentOpen(false);
    onChange && onChange(editor.getHTML(), editor.getJSON());
  };

  const setBold = (editor: Editor) => {
    const { view, state } = editor;
    const { from, to } = view.state.selection;
    if (from !== undefined && to !== undefined) {
      const selectedText = state.doc.textBetween(from, to);
      const boldText = convertToBold(selectedText);
      const textNode = editor.schema.text(boldText);
      const transaction = state.tr.replaceWith(from, to, textNode);
      view.dispatch(transaction);
    }
  };

  const handleUndo = () => {
    editor?.commands.undo();
  };

  const handleRedo = () => {
    editor?.commands.redo();
  };

  return (
    <Stack direction="column" height={'100%'} spacing={1} width={'100%'}>
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #BBBBBB',
          borderRadius: '8px',
          cursor: 'text',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <Box
          minHeight={300}
          sx={{
            display: 'block',
            overflowY: 'auto',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          {editor && <EditorContent editor={editor} style={{ height: '100%' }} />}
        </Box>
        <Box
          height={'fit-content'}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
        >
          {!status?.isEditDisabled && (
            <Stack
              direction="row"
              justifyContent={isLink ? 'flex-end' : 'space-between'}
              sx={{
                width: '100%',
                bottom: 0,
                p: '10px 20px 10px 10px',
              }}
            >
              {editor && aiAssistant && !isLink && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <AIAssistantMenu
                    postId={postId}
                    editor={editor}
                    onChange={onChange as any}
                    onLoading={(state) => {
                      editor?.setOptions({
                        editable: !state,
                      });
                    }}
                  />
                </Box>
              )}
              <Stack direction="row" gap={1} alignItems="center">
                {status?.isAdvocacyParent && (
                  <Tooltip title="Let others edit the content" arrow placement="top">
                    <IconButton onClick={toggleAdvocacyEditable}>
                      {advocacyEditable ? <LockOpen /> : <Lock />}
                    </IconButton>
                  </Tooltip>
                )}
                {status?.charsCount !== undefined && <CharCounter />}
                {editor && (
                  <Tooltip title={t('Bold')} placement="top">
                    <IconButton onClick={() => setBold(editor)}>
                      <FormatBoldRounded />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={t('Insert mention')} placement="top">
                  <IconButton onClick={handleTaggerComponentOpen} ref={anchorRef}>
                    <AlternateEmailIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('Insert emoji')} placement="top">
                  <IconButton
                    data-testid="emoji-button"
                    onClick={handleEmojiComponentOpen}
                    ref={anchorRef}
                  >
                    <InsertEmoticon />
                  </IconButton>
                </Tooltip>
                {showNoSignatureModal && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: '50px',
                      left: '80%',
                      transform: 'translateX(-40%)',
                      backgroundColor: 'white',
                      border: '1px solid #BBBBBB',
                      borderRadius: '8px',
                      padding: '20px',
                      zIndex: 1000,
                    }}
                  >
                    {!postId ? (
                      <Typography variant="body2" color="error">
                        Save a draft post first if you want to add the signature.
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        {data?.post?.brand?.user?._id === user?._id
                          ? "You don't have a signature."
                          : `${`${data?.post?.brand?.name || 'The brand user'} 
                            `.trim()} doesn't have a signature.`}
                      </Typography>
                    )}

                    {/* Show 'Add one' prompt only if the logged-in user is missing the signature */}
                    {postId && data?.post?.brand?.user?._id === user?._id && (
                      <span
                        style={{ color: 'hotpink', cursor: 'pointer' }}
                        onClick={() => {
                          navigate('/settings', {
                            state: { tab: 0, openEssentialInfo: true },
                          });
                        }}
                      >
                        Add one
                      </span>
                    )}

                    <IconButton onClick={() => setShowNoSignatureModal(false)}>
                      <Undo />
                    </IconButton>
                  </Box>
                )}
                {!isLink && (
                  <Tooltip title={t('Insert signature')} placement="top">
                    <Box
                      sx={{
                        position: 'relative', // Parent must be relative for Chip's absolute positioning
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px', // Set a width that provides enough space for the icon and Chip
                        height: '40px', // Set a height to ensure room for the Chip
                      }}
                    >
                      <IconButton
                        onClick={handleSignatureToggle}
                        style={{ color: isSignatureChecked ? 'blue' : 'inherit' }}
                      >
                        <img
                          src={SignIcon}
                          alt="signature icon"
                          width="20"
                          style={{
                            filter: isSignatureChecked
                              ? 'invert(34%) sepia(100%) saturate(7472%) hue-rotate(180deg) brightness(95%) contrast(101%)'
                              : 'none',
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Tooltip>
                )}

                {editor && (
                  <EmojiComponent
                    open={emojiComponentOpen}
                    anchorElement={anchorRef.current}
                    onClose={handleEmojiComponentClose}
                    onChange={(emoji) => handleEmojiInputChange(editor, emoji)}
                  />
                )}
                {editor && (
                  <TaggerSelector
                    open={taggerComponentOpen}
                    anchorElement={anchorRef.current}
                    onClose={handleTaggerComponentClose}
                    onChange={(tag, tagId) => handleTagger(editor, tag, tagId)}
                  />
                )}
                <Divider orientation="vertical" variant="middle" flexItem />
                {isUndoable && (
                  <>
                    <Tooltip title={t('Undo')} placement="top">
                      <IconButton onClick={handleUndo} ref={anchorRef}>
                        <Undo />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('Redo')} placement="top">
                      <IconButton onClick={handleRedo} ref={anchorRef}>
                        <Redo />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Stack>
            </Stack>
          )}

          {status?.isEditDisabled && !status.isCurrentUserEditing && (
            <Stack
              direction={'row'}
              sx={{
                p: '8px',
                backgroundColor: '#f0c0c0',
                width: '100%',
                justifyContent: 'space-between',
                color: 'hsla(0, 0%, 43%, 1)',
              }}
            >
              <Typography fontWeight={600} fontSize={14} ml={0.5}>
                {t(
                  "This post is currently being edited by another user. You'll be able to make edits once it's available.",
                )}
              </Typography>
              <Lock />
            </Stack>
          )}
        </Box>
      </Box>
    </Stack>
  );
};
